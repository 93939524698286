import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../customComponents/Button";
import Paper from "../widgets/Paper";
import InputText from "../customComponents/InputText";
import Select from "../customComponents/Select"; // Assuming there's a single-select component
import MultiSelect from "../customComponents/MultiSelect";
import Upload from "../customComponents/Upload";
import Loader from "../widgets/Loader";
import { post } from "../utils/api";
import { toast } from "react-toastify";

const AddUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, type } = location.state || {};
  const [loading, setLoading] = useState(false);

  const [formState, setFormState] = useState({
    username: "",
    password: "",
    role: "",
    email: "",
    mobilenumber: "",
    assigncity: null,
    location: [],
    institution: [],
    profilephoto: null,
    isactive: true,
    type: type || "insert",
    userid: user?.userid || null,
  });

  const [locations, setLocations] = useState([]);
  const [institutions, setInstitutions] = useState([]);

  const cityData = {
    Bangalore: {
      locations: [
        { id: 1, value: "HSR", label: "HSR" },
        { id: 2, value: "Koramangala", label: "Koramangala" },
      ],
      institutions: [
        { id: 1, value: "HSR Institution", label: "HSR Institution" },
        {
          id: 2,
          value: "Koramangala Institution",
          label: "Koramangala Institution",
        },
      ],
    },
    Chennai: {
      locations: [
        { id: 1, value: "Adyar", label: "Adyar" },
        { id: 2, value: "T Nagar", label: "T Nagar" },
      ],
      institutions: [
        { id: 1, value: "Adyar Institution", label: "Adyar Institution" },
        { id: 2, value: "T Nagar Institution", label: "T Nagar Institution" },
      ],
    },
    Mumbai: {
      locations: [
        { id: 1, value: "Andheri", label: "Andheri" },
        { id: 2, value: "Bandra", label: "Bandra" },
      ],
      institutions: [
        { id: 1, value: "Andheri Institution", label: "Andheri Institution" },
        { id: 2, value: "Bandra Institution", label: "Bandra Institution" },
      ],
    },
  };

  const handleSelectChange = (value, name) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "assigncity") {
      loadLocations(value?.value);
      setFormState((prevState) => ({
        ...prevState,
        location: [],
        institution: [],
      }));
    }

    if (name === "location") {
      loadInstitutions(value.map((v) => v.value));
      setFormState((prevState) => ({ ...prevState, institution: [] }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // Allow only valid email characters
      const validEmailChars = /^[a-zA-Z0-9._%+-@]*$/;
      if (validEmailChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else if (name === "mobilenumber") {
      // Allow only numeric input for mobile number
      const validNumberChars = /^[0-9]*$/;
      if (validNumberChars.test(value)) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const loadLocations = (city) => {
    const cityInfo = cityData[city];
    if (cityInfo) {
      setLocations(cityInfo.locations);
      setInstitutions(cityInfo.institutions);
    } else {
      setLocations([]);
      setInstitutions([]);
    }
  };

  const loadInstitutions = (selectedLocations) => {
    const city = formState.assigncity?.value;
    if (city) {
      const cityInfo = cityData[city];
      if (cityInfo) {
        setInstitutions(cityInfo.institutions);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setFormState({
        ...user,
        password: "",
        type: "update",
        role: { value: user.role, label: user.role },
        assigncity: { value: user.assigncity, label: user.assigncity },
        isactive: user.isactive ?? true,
      });
      loadLocations(user.assigncity);
    }
  }, [user]);

  const handleFileSelect = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormState((prevState) => ({
        ...prevState,
        profilephoto: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };

  const validateInput = (data) => {
    const errors = {};

    if (!data.username || data.username.trim() === "") {
      errors.username = "Username is required.";
    }

    if (!data?.assigncity?.value || data?.assigncity?.value.trim() === "") {
      errors.password = "City is required.";
    }
    if (!data?.role?.value || data?.role?.value.trim() === "") {
      errors.password = "Designation is required.";
    }

    if (!data.email || data.email.trim() === "") {
      errors.email = "Email is required.";
    }

    if (!data.mobilenumber || data.mobilenumber.trim() === "") {
      errors.mobilenumber = "Mobile number is required.";
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        ...formState,
        assigncity: formState?.assigncity?.value,
        role: formState?.role?.value,
        location: formState?.location.map((loc) => loc.value),
        institution: formState?.institution.map((inst) => inst.value),
        type: formState.userid ? "update" : "insert",
        isactive: formState.isactive || true,
      };
      await post("/addUser", payload);
      navigate("/users");
    } catch (error) {
      toast.error("An error occurred while adding the user."); // Show toast error message for any exceptions
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex justify-between flex-wrap">
            <div>
              <h2 className="text-primary-black text-[24px] font-semibold">
                Add User
              </h2>
              <p className="text-[12px]">
                <span className="font-bold">Users</span> /{" "}
                <span className="font-regular">Add User</span>
              </p>
            </div>
            <div className="flex gap-4 flex-wrap">
              <Button type="primary" label="Save" onClick={handleSave} />
              <Button
                type="secondary"
                label="Cancel"
                onClick={handleNavigateBack}
              />
            </div>
          </div>

          <Paper className="my-8 py-12 border">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {/* Profile Photo Upload */}
              <div>
                <Upload onFileSelect={handleFileSelect} />
              </div>

              {/* User Information */}
              <div className="col-span-2 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    User Name <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="username"
                    value={formState.username}
                    onChange={handleInputChange}
                    placeholder="User Name"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-4 block">
                    Designation <span className="text-red-500">*</span>
                  </label>
                  <Select
                    name="role"
                    value={formState.role}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "role")
                    }
                    options={[
                      { value: "Sales Manager", label: "Sales Manager" },
                      { value: "Sales Executive", label: "Sales Executive" },
                      { value: "Sales Executive", label: "Sales Executive" },
                      { value: "Sales Executive", label: "Sales Executive" },
                    ]}
                    placeholder="Select Designation"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Mobile Number <span className="text-red-500">*</span>
                  </label>
                  <InputText
                    name="mobilenumber"
                    value={formState.mobilenumber}
                    onChange={handleInputChange}
                    placeholder="Mobile Number"
                  />
                </div>

                {/* Assign Institution Section */}
                <div className="col-span-full">
                  <h3 className="text-[20px] font-bold">Assign Institution</h3>
                </div>

                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Assign City <span className="text-red-500">*</span>
                  </label>
                  <Select
                    name="assigncity"
                    value={formState.assigncity}
                    onChange={(selectedOption) =>
                      handleSelectChange(selectedOption, "assigncity")
                    }
                    options={[
                      { value: "Bangalore", label: "Bangalore" },
                      { value: "Chennai", label: "Chennai" },
                      { value: "Mumbai", label: "Mumbai" },
                    ]}
                    placeholder="Select Location"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Location <span className="text-red-500">*</span>
                  </label>
                  <MultiSelect
                    name="location"
                    value={formState.location}
                    onChange={(selectedOptions) =>
                      handleSelectChange(selectedOptions, "location")
                    }
                    options={locations}
                    placeholder="Select Location(s)"
                  />
                </div>
                <div>
                  <label className="text-[16px] font-semibold mb-2 block">
                    Institution <span className="text-red-500">*</span>
                  </label>
                  <MultiSelect
                    name="institution"
                    value={formState.institution}
                    onChange={(selectedOptions) =>
                      handleSelectChange(selectedOptions, "institution")
                    }
                    options={institutions}
                    placeholder="Select Institution(s)"
                  />
                </div>
              </div>
            </div>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default AddUser;
